<template>
	<div>
		<button
			v-if="!isExpanded || !hideTitleOnExpand"
			class="relative w-full"
			:class="[titleClasses, {[activeTitleClasses]: isExpanded}]"
			aria-label="expand/collapse"
			@click="toggleCollapse"
		>
			<slot name="title" />
			<span
				v-if="showIcon"
				:class="[isExpanded ? activeIconClasses : iconClasses]"
				class="absolute transform -translate-y-1/2 top-1/2 right-3"
			>
				<slot
					name="icon"
					:is-expanded="isExpanded"
				>
					<PlusIcon
						v-show="!isExpanded"
						class="h-6"
					/>
					<MinusIcon
						v-show="isExpanded"
						class="h-6"
					/>
				</slot>
			</span>
			<span
				v-else-if="showArrow"
				:class="[isExpanded ? activeIconClasses : iconClasses]"
			>
				<slot
					name="icon"
					:is-expanded="isExpanded"
				>
					<ArrowDown
						:class="[
							'transition-all ease-in h-3 w-3 mx-2',
							{ 'transform rotate-180': !isExpanded }
						]"
					/>
				</slot>
			</span>
		</button>
		<transition name="fade-and-slide">
			<slot
				v-if="isExpanded"
				name="content"
			/>
		</transition>
		<button
			v-if="isExpanded && hideTitleOnExpand"
			class="relative w-full"
			:class="[titleClasses, {[activeTitleClasses]: isExpanded}]"
			@click="toggleCollapse"
		>
			<slot
				name="close"
			/>
		</button>
	</div>
</template>

<script async>
import ArrowDown from '@/components/icons/ArrowDown.vue'
import MinusIcon from '@/components/icons/MinusIcon.vue'
import PlusIcon from '@/components/icons/PlusIcon.vue'

export default {
	components: {
		PlusIcon,
		MinusIcon,
		ArrowDown
	},
	props: {
		expanded: {
			type: Boolean,
			default: false
		},
		collapsable: {
			type: Boolean,
			default: true
		},
		showIcon: {
			type: Boolean,
			default: false
		},
		showArrow: {
			type: Boolean,
			default: false
		},
		titleClasses: {
			type: String,
			default: 'focus:ring focus:ring-green-300'
		},
		iconClasses: {
			type: String,
			default: 'text-gray-500'
		},
		activeTitleClasses: {
			type: String,
			default: ''
		},
		activeIconClasses: {
			type: String,
			default: ''
		},
		hideTitleOnExpand: {
			type: Boolean,
			default: false
		}
	},
	emits: [ 'expanded' ],
	data() {
		return {
			isExpanded: this.expanded
		}
	},
	computed: {
		arrowRotation() {
			return this.isExpanded ? '' : 'transform rotate-180'
		}
	},
	watch: {
		expanded (newValue) {
			this.isExpanded = newValue
		},
		isExpanded(newValue) {
			this.$emit('expanded', newValue)
		}
	},
	methods: {
		toggleCollapse () {
			if (this.collapsable) {
				this.isExpanded = !this.isExpanded
				if (this.isExpanded) this.$emit('expanded')
				else this.$emit('collapsed')
			}
		}
	}
}
</script>
