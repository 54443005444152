<template>
	<div class="sticky w-48 top-16">
		<WwCollapse
			:expanded="!isMobile"
			:collapsable="isMobile"
		>
			<template #title>
				<span class="flex items-center w-full p-0 mb-2 text-left text-black border-0 flex-nowrap bg-none md:bg-none md:pointer-events-none md:text-left md:p-0 md:text-lg md:font-semibold">
					<img
						src="@/assets/icons/list.svg"
						alt="List"
						height="16"
						width="16"
						class="mr-1.5"
					> Table of Contents
				</span>
			</template>
			<template #content>
				<ul class="p-0 m-0">
					<li
						v-for="(category, key) in features"
						:id="`section-${formatStr(getCategoryTitle(key))}`"
						:key="key"
						class="w-full text-left"
					>
						<div class="py-1 font-bold">
							{{ getCategoryTitle(key) }}
						</div>
						<a
							v-for="(article, index) in category"
							:id="'article-' + formatStr(article.title)"
							:key="index"
							:href="`#${getArticlePath(getCategoryTitle(key), article.title)}`"
							:class="{ 'font-semibold text-green-500' : getArticlePath(getCategoryTitle(key), article.title) === currentElement }"
							class="block w-full ml-2 text-left"
							@click.stop="handleArticleClick(getCategoryTitle(key), article.title)"
						>
							{{ article.title }}
						</a>
					</li>
				</ul>
			</template>
		</WwCollapse>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import WwCollapse from '@/components/multiUse/WwCollapse.vue'
import requireIcon from '@/utils/requireIcon.js'

export default {
	components: {
		WwCollapse
	},
	props: {
		features: {
			type: Object,
			default: () => {}
		},
		currentElement: {
			type: String,
			default: ''
		},
		guideCategories: {
			type: Array,
			default: () => []
		}
	},
	emits: [ 'set-current-element' ],
	computed: {
		...mapGetters([ 'isMobile' ]),
		isBaseUrl() {
			return !this.$route.hash
		}
	},
	mounted() {
		this.setCurrentElementAsFirstSection()
	},
	methods: {
		setCurrentElementAsFirstSection() {
			if (this.isBaseUrl) {
				this.$emit('set-current-element', this.formatStr(this.guideCategories[0]), this.formatStr(this.features.visit[0].title))
			}
		},
		getArticlePath(categoryName, articleName) {
			categoryName = this.formatStr(categoryName)
			articleName = this.formatStr(articleName)
			return categoryName + '/' + articleName
		},
		handleArticleClick: function (categoryName, articleName) {
			categoryName = this.formatStr(categoryName)
			articleName = this.formatStr(articleName)
			this.$emit('set-current-element', categoryName, articleName)
		},
		formatStr: function (string) {
			const toKebabCase = str =>
				str &&
				str
					.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
					.map(x => x.toLowerCase())
					.join('-')
			return toKebabCase(string)
		},
		requireIcon: requireIcon,
		getCategoryTitle(category) {
			return this.guideCategories.find(element => element.toLowerCase().includes(category))
		}
	}
}
</script>
